<style lang="scss">
.martkplatz-wohnen {
  .head-row-text-normal {
    .text-normal {
      display: inline-block;
      text-transform: none !important;
    }
  }

  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .bild-box {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .leistung {
    strong {
      display: block;
      font-size: 140%;
      text-transform: uppercase;
    }
    &:before {
      content: '';
      display: block;
      margin: 20px 0;
      width: 150px;
      height: 5px;
      background: $blue-darker;
    }
    &:after {
      content: '';
      display: block;
      margin: 20px 0;
      width: 100px;
      height: 5px;
      background: $blue-darker;
    }
  }
  .text-center-small {
    @media (max-width: 992px) {
      text-align: center !important;
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
      max-height: 120px;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
  .router-link-exact-active {
    &:after {
      display: none;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>
<template>
  <div class="martkplatz-wohnen content container">
    <h1>Die schönsten Seiten des Wohnens</h1>
    <p class="intro-txt">Wohnen hat so viele Seiten – und wir zeigen Sie gerne. Jeden Mittwoch und Freitag (Hauptmarkt) im Wohnen-Teil der Kleinen Zeitung und zehn Mal im Jahr in Form eines attraktiven Wohnen-Journals als aufmerksamkeitsstarke Beilage in der Zeitung.</p>
    <p>Von der <strong>klassischen Immobilien-Anzeige bis hin zum umfangreichen Immobilien-Porträt:</strong> Wir bieten Ihnen die beste Lösung für Ihren Kommunikationsbedarf.</p>
    <div class="margin-t-s"><h2>Ihre Werbelösungen für Wohnen</h2></div>
    <TabGroup>
      <Tab tabid="wohnen-tab1" :title="'Raum- und Zeilenanzeigen'" active>
        <div class="row margin-tb-s">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <h2>Raum- und Zeilenanzeigen</h2>
            <p>Präsentieren Sie Ihre Angebote im größten Immobilienmarkt im Süden Österreichs.</p>
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Hauptblatt): </strong><br />
              Donnerstag, 10 Uhr / Donnerstag, 11 Uhr für Freitag<br />
              Dienstag, 10 Uhr / Dienstag, 11 Uhr für Mittwoch
            </p>
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Beilage Kleinanzeigenteil):</strong><br />
              Mittwoch, 18 Uhr
            </p>
            <p>
              <strong>Anzeigenschluss / Druckunterlagenschluss (Beilage redaktioneller Teil): </strong><br />
              Freitags vor Erscheinung. 11 Uhr / Mittwoch 18 Uhr
            </p>
            <p>
              <strong>Wiederholungsschaltung Raumanzeigen</strong><br />
              50 % auf den Normaltarif bei Schaltung des gleichen Sujets wie bei Erstschaltung innerhalb von 14 Tagen.
            </p>
            <p>
              <strong>Wiederholungsschaltung Zeilenanzeigen</strong><br />
              50 % auf den Normaltarif bei Schaltung des gleichen Sujets wie bei Erstschaltung innerhalb von 7 Tagen nur innerhalb des Hauptmarktes (Freitag) und Zweitmarktes (Mittwoch) möglich.
            </p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content//marktplaetze/prod-wohnen-v2.png" alt="Beruf und Bildung" class="img-fluid" />
          </div>
        </div>

        <!-- 
        <div class="tabelle-box tabelle-width-50">
          <div class="headline">
            <strong>Millimeterpreis 4C</strong>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-3"></div>
            <div class="col-lg-3 text-align-right">Kombination</div>
            <div class="col-lg-3 text-align-right">Steiermark</div>
            <div class="col-lg-3 text-align-right">Kärnten</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">MO - FR</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">20,68</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">14,83</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">9,07</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-3 text-align-center">SA - SO</div>
            <div class="col-6 text-align-left visible-small">Kombination</div>
            <div class="col-lg-3 col-6 text-align-right">21,88</div>
            <div class="col-6 text-align-left visible-small">Steiermark</div>
            <div class="col-lg-3 col-6 text-align-right">15,48</div>
            <div class="col-6 text-align-left visible-small">Kärnten</div>
            <div class="col-lg-3 col-6 text-align-right">9,47</div>
          </div>
        </div>
         -->
        <div class="text-center">
          <CollapsibleButton link="wohnen-raum-zeilenanzeigen-tabellen" class="content-link-mehrinfo"><span class="material-icons">arrow_right_alt</span>Ihre Werbemöglichkeiten</CollapsibleButton>
        </div>
        <Collapsible link="wohnen-raum-zeilenanzeigen-tabellen">
          <h2>Raumanzeigen</h2>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Millimeterpreise </strong>
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">20,99</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">15,05</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">9,21</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3"></div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/8 Seite</strong>
                  Fixformat: 47 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.833,65</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">2.031,75</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">1.243,35</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-47x135.svg" alt="47x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/4 Seite</strong>
                  Fixformat: 98 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5.667,30</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">4.063,50</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">2.486,70</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-98x135.svg" alt="98x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/2 Seite</strong>
                  Fixformat: 200 x 135 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">11.334,60</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">8.127,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">4.973,40</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x135.svg" alt="200x135" class="img-fluid " />
            </div>
          </div>
          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>1/1 Seite</strong>
                  Fixformat: 200 x 275 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI oder FR</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">23.089,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">16,555,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">10.131,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x275.svg" alt="200x135" class="img-fluid " />
            </div>
          </div>

          <div class="row format-box">
            <div class="col-lg-7 order-lg-2">
              <div class="tabelle-box">
                <div class="headline">
                  <strong>Rubrikenvorspann</strong>
                  Format: 200 x 110 mm
                </div>
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-center">MI</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">2.050,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">1.550,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">950,00</div>
                </div>
                <div class="info-txt-tabelle">Erscheinung nur mittwochs möglich.<br />Pro Mittwochausgabe nur ein Rubrikenvorspann verfügbar.<br />PR-Produktionskosten im Tarif enthalten.</div>
              </div>
            </div>
            <div class="col-lg-3">
              <img src="@/assets/img/icons/TARIF-Inserat-ICONS-200x110.svg" alt="200x110" class="img-fluid " />
            </div>
          </div>
          <div class="margin-tb-m"></div>
          <h2>Zeilenanzeigen</h2>
          <div class="row">
            <div class="col-lg-8 justify-content-center align-self-center">
              <div class="tabelle-box alignItems">
                <div class="row head-row hide-small">
                  <div class="col-lg-3"></div>
                  <div class="col-lg-3 text-align-right">Kombination</div>
                  <div class="col-lg-3 text-align-right">Steiermark</div>
                  <div class="col-lg-3 text-align-right">Kärnten</div>
                </div>

                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">1. Zeile</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">16,79</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">10,54</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">8,14</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Zusatzzeile</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">12,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">7,67</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5,72</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Logo 4c</span> je Zeile</div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">16,73</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">13,21</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">9,68</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Foto</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">66,21</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">52,01</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">39,27</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Icon</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Energiezeile</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">7,50</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">5,25</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">3,25</div>
                </div>
                <div class="row content-row">
                  <div class="col-lg-3 text-align-left"><span class="strong">Online-Code</span></div>
                  <div class="col-6 text-align-left visible-small">Kombination</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                  <div class="col-6 text-align-left visible-small">Steiermark</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                  <div class="col-6 text-align-left visible-small">Kärnten</div>
                  <div class="col-lg-3 col-6 text-align-right">5,00</div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 justify-content-center align-self-center">
              <img src="@/assets/img/img-content//branchenloesungen/bsp-wohnen.jpg" alt="Bsp. Wohnen" class="img-fluid" />
              <div class="text-center">
                <small>UMSETZUNGSBEISPIEL</small>
              </div>
            </div>
          </div>
          <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 5 % Werbeabgabe und 20 % Umsatzsteuer.</div>
        </Collapsible>
      </Tab>
      <Tab tabid="wohnen-tab4" :title="'Advertorial'">
        <div class="row justify-content-center align-self-center">
          <div class="col-lg-6 justify-content-center align-self-center">
            <h2>Leistungen &amp; Tarif</h2>
            <ul class="content-ul">
              <li>Content-Produktion inkl. Bildersuche</li>
              <li>Korrektorat (2 Korrekturschleifen)</li>
              <li>Anlage des Artikels auf kleinezeitung.at</li>
              <li>Vorlaufzeit 5 Werktage</li>
            </ul>
            <small>Verrechnung über Auktionsguthaben nicht möglich.</small>

            <div class="tabelle-box">
              <div class="row head-row ">
                <div class="col-lg-8 text-align-left hide-small"></div>
                <div class="col-lg-4 text-align-right hide-small">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-6 col-8 text-align-left ">* Produktion</div>
                <div class="col-lg-6 col-4 text-align-right">290,00</div>
              </div>
              <div class="info-txt-tabelle">* Produktionskosten sind nicht rabattfähig</div>
              <div class="info-txt-tabelle">
                Nur in Kombination mit einem Native Ad (Mindesbuchungsvolumen: 500,00 EURO) oder Performance Upgrade buchbar.
              </div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <img src="@/assets/img/img-content/branchenloesungen/sponsored-story-wohnen.jpg" alt="Sponsored Story" class="img-fluid img-center img-s-75" />
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>
      </Tab>
      <Tab tabid="wohnen-tab6" :title="'willhaben.at'">
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center order-lg-2">
            <p>Profitieren Sie von der optimalen Kombination „Kleine Zeitung“ und „willhaben“. Mit der reichweitenstärksten Tageszeitung im Süden Österreichs und dem größten digitalen Marktplatz des Landes nutzen Sie das erfolgreichste Kommunikationsnetzwerk Österreichs.</p>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/klz-willhaben.jpg" alt="Kleine Zeitung Willhaben" class="img-fluid img-s-60 img-center" />
            </div>
          </div>
        </div>
        <h3>Ihre Werbemöglichkeiten als Add-On zur Kleinen Zeitung.</h3>
        <div class="row">
          <div class="col-lg-6 justify-content-center align-self-center">
            <ul class="content-ul">
              <li>Billboard 970 x 250 px</li>
              <li>run over vertical</li>
              <li>Anzeigendetailansicht</li>
              <li>Laufzeit 4 Wochen</li>
            </ul>
            <div class="tabelle-box alignItems">
              <div class="row head-row head-row-text-normal">
                <div class="col-lg-4 col-6 text-align-left hide-small"></div>
                <div class="col-lg-4 col-6 text-align-right text-left-small"><span class="text-normal">Als</span></div>
                <div class="col-lg-4 col-6 text-align-right">Tarif</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Small</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">10.000</div>
                <div class="col-lg-4 col-6 text-align-right">270,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Medium</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">20.000</div>
                <div class="col-lg-4 col-6 text-align-right">500,00</div>
              </div>
              <div class="row content-row">
                <div class="col-lg-4 col-12 text-align-left text-center-small ">Large</div>
                <div class="col-lg-4 col-6 text-align-right text-left-small">30.000</div>
                <div class="col-lg-4 col-6 text-align-right">660,00</div>
              </div>
              <div class="info-txt-tabelle">Anforderungen ans Werbemittel: max. 150kb. Gängige Bildformate (jpg, png, gif etc.) oder HTML Werbemittel sind möglich (hier sind clicktags nach iab Standard einzubauen)</div>
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content//marktplaetze/wohnen-laptop-willhaben.jpg" alt="Wohnen Laptop Willhaben" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer. Keine Rabatte möglich.</div>
      </Tab>
    </TabGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';
import CollapsibleButton from '@/components/util/CollapsibleButton.vue';
import Collapsible from '@/components/util/Collapsible.vue';

export default defineComponent({
  components: {
    //Modal,
    TabGroup,
    Tab,
    CollapsibleButton,
    Collapsible,
  },
  setup() {
    const navigateHook = (navigate: any) => {
      navigate();
    };
    return {
      navigateHook,
    };
  },
});
</script>
